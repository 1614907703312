<template>
  <div class="section-parallax" :class="{ black: isBlack }">
    <div class="parallax">
      <h1 :data-en="en" :data-id="id">{{ title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "Parallax",
  props: {
    title: String,
    en: String,
    id: String,
    isBlack: Boolean,
  },
};
</script>
<template>
  <div class="d-md-none toggle-mobile">
    <input type="checkbox" id="toggle-mode-cb-mobile" />
    <label
      id="toggle-mode-mobile"
      for="toggle-mode-cb-mobile"
      class="mode-toggle"
    >
      <span class="toggle-border">
        <span class="toggle-indicator"></span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "ToggleMobile",
};
</script>
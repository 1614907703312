<template>
  <a
    :href="link"
    rel="noopener"
    target="_blank"
    class="d-flex align-items-center fw-semibold link-arrow mt-3"
    :class="{ 'text-white': isWhite, 'text-black': isBlack }"
    v-if="isLinkExternal"
  >
    <span :data-en="en" :data-id="id">{{ title }}</span>
    <svg
      width="23"
      height="12"
      viewBox="0 0 23 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6H21.25M21.25 6L18.3333 3.08333L16.25 1L21.25 6ZM21.25 6L18.3333 8.91667L16.25 11"
        stroke="#101010"
        stroke-width="1.6"
      />
    </svg>
  </a>
  <router-link
    :to="{ name: link }"
    class="d-flex align-items-center fw-semibold link-arrow mt-3"
    :class="{ 'text-white': isWhite, 'text-black': isBlack }"
    v-else
  >
    <span :data-en="en" :data-id="id">{{ title }}</span>
    <svg
      width="23"
      height="12"
      viewBox="0 0 23 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6H21.25M21.25 6L18.3333 3.08333L16.25 1L21.25 6ZM21.25 6L18.3333 8.91667L16.25 11"
        stroke="#101010"
        stroke-width="1.6"
      />
    </svg>
  </router-link>
</template>

<script>
export default {
  name: "LinkArrow",
  props: {
    title: String,
    en: String,
    id: String,
    link: String,
    isWhite: Boolean,
    isBlack: Boolean,
    isLinkExternal: Boolean,
  },
};
</script>

<style>
</style>
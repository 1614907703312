export default function initParallax() {
  const parallaxTexts = document.querySelectorAll(".parallax");

  if (parallaxTexts.length) {
    window.addEventListener("scroll", () => {
      const pageY = window.pageYOffset;

      parallaxTexts.forEach((text) => {
        text.style.transform = `translateX(${pageY / 10}px)`;
      });
    });
  }
}
